import {
  Alert,
  AlertIcon,
  Button,
  Image,
  Input,
  Textarea,
} from "@chakra-ui/react";
import { logEvent } from "firebase/analytics";
import { doc, setDoc } from "firebase/firestore";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";
import { analytics, firestore } from "../firebase";
import { currentHuntState, detailsFormState, windowSizeState } from "../recoil";
import logo from "./../Home/nz country.png";
import { Helmet } from "react-helmet";

export const Inquire = () => {
  const { width, height } = useRecoilValue(windowSizeState);
  const [formValues, setFormValues] = useRecoilState(detailsFormState);
  const [isMessage, setIsMessage] = useState(false);
  const currentHunt = useRecoilValue(currentHuntState);
  const nav = useNavigate();

  useEffect(() => {
    logEvent(analytics, "page_view", { page_title: "Inquire" });
  }, []);

  const saveForm = () => {
    const stags = Object.entries(currentHunt).filter(
      (item) => item[0].length <= 3 && item[1] > 0
    ); //.map((item) => item)

    const htmlEmail = `<div style="background-color: #FFFFFF; max-width: 540pt; padding: 0pt 36pt 72pt 36pt;">
    <div style="padding-top: 0rem; background-image: url(https://firebasestorage.googleapis.com/v0/b/nzcountry.appspot.com/o/images%2FEmail%20Head.jpg?alt=media&token=8f5a18cb-7475-425f-b1d4-2a7c00578bd6); background-position: center; background-size: cover; object-fit: contain; color: #EA9101; width: 600px; height: 100px; text-align: center;"></div>
        <h2 style="padding-top: 18pt; padding-bottom: 6pt; line-height: 1.15; page-break-after: avoid; orphans: 2; widows: 2; text-align: left;">
          <b>New Client Request</b>
        </h2>
        <div style="padding-bottom: 1rem; color: #000000; font-weight: 400; text-decoration: none; vertical-align: baseline; font-size: 11pt; font-family: "Work Sans"; font-style: normal;">Name: ${
          formValues.name
        } ${formValues.lastName}</div>
        <div style="padding-bottom: 2rem; color: #000000; font-weight: 400; text-decoration: none; vertical-align: baseline; font-size: 11pt; font-family: "Work Sans"; font-style: normal;">Contact: ${
          formValues.phone
        } ${formValues.email}</div>
        <h3 style="padding-bottom: .5rem; color: #000000; font-weight: 400; text-decoration: none; vertical-align: baseline; font-size: 11pt; font-family: "Work Sans"; font-style: normal;"><b>Message: ${
          formValues.message
        }</b></h3>
        <h3 style="padding-bottom: 2rem; color: #000000; font-weight: 400; text-decoration: none; vertical-align: baseline; font-size: 11pt; font-family: "Work Sans"; font-style: normal;">Calculator selection:</h3>${stags.map(
          (item) =>
            `<div style="color: #000000; font-weight: 400; text-decoration: none; vertical-align: baseline; font-size: 11pt; font-family: "Work Sans"; font-style: normal;">Red Stag SCI ${item[0]}: ${item[1]}</div>`
        )}
        ${Object.entries(currentHunt)
          .filter((item) => item[0].length > 3 && item[1] > 0)
          .map(
            (item) =>
              `<div style="padding-bottom: 2rem; color: #000000; font-weight: 400; text-decoration: none; vertical-align: baseline; font-size: 11pt; font-family: "Work Sans"; font-style: normal;">${item[0]}: ${item[1]}</div>`
          )}
    
        </div>`;

    const data = {
      to: ["mark@nzcountry.guide"],
      message: {
        subject: "Expression of Interest from NZ COUNTRY Website",
        text: `Name: ${formValues.name} ${formValues.lastName} \n Contact: ${formValues.phone} ${formValues.email}`,
        html: htmlEmail,
      },
    };

    setDoc(doc(firestore, `mail/${new Date().getTime()}`), data)
      .then(() => {
        logEvent(analytics, "sign_up", { method: "Inquire form submission" });

        setIsMessage(true);
        setTimeout(() => {
          setIsMessage(false);
        }, 3000);
      })
      .catch((err) => console.error(err));
  };

  const validateName = (value: string) => {
    let error;
    if (!value) {
      error = "Name is required";
    } else if (value.length < 2) {
      error = "Something went wrong.";
    }
    return error;
  };

  const schema = {
    "@context": "https://schema.org",
    "@type": "ContactAction",
    name: "Contact Us",
    target: {
      "@type": "EntryPoint",
      urlTemplate: "https://www.nzcountry.guide/inquire",
      actionPlatform: [
        "http://schema.org/DesktopWebPlatform",
        "http://schema.org/MobileWebPlatform",
      ],
    },
    description: "Reach out to us for guided hunting inquiries or feedback.",
    potentialAction: {
      "@type": "CommunicateAction",
      recipient: {
        "@type": "ContactPoint",
        name: "Customer Support",
        contactType: "Customer Service",
        email: "mark@nzcountry.guide",
        availableLanguage: ["English"],
      },
    },
  };

  return (
    <>
      <Helmet>
        <script type="application/ld+json">{JSON.stringify(schema)}</script>
      </Helmet>
      <div
        style={{
          height: "100vh",
          overflowY: "hidden",
          backgroundColor: "rgba(0,0,0,.9)",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            paddingTop: `${height / 20}px`,
            //paddingBottom: `${height / 20}px`,
            //overflowY: "scroll",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              height: `${(height / 28) * 3}px`,
              width: "95%",
              maxWidth: "1200px",

              color: "#EA9101",
              fontFamily: "anton",
              fontSize: width < 550 ? "30px" : `${width / 30}px`,
              borderRadius: "3px",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                height: "100%",
              }}
            >
              YOUR HUNT
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              width: "90%",
              maxWidth: "300px",
              justifyContent: "space-between",
            }}
          >
            <Button
              variant="link"
              size="lg"
              color={"#B0B0A0"}
              onClick={() => nav("/")}
              fontFamily={"anton"}
              letterSpacing={1.5}
            >
              Home
            </Button>
            <Button
              variant="link"
              size="lg"
              color={"#B0B0A0"}
              onClick={() => nav("/trophy-Hunting")}
              fontFamily={"anton"}
              letterSpacing={1.5}
            >
              Trophy Hunts
            </Button>
            <Button
              variant="link"
              size="lg"
              color={"#B0B0A0"}
              onClick={() => nav("/team")}
              fontFamily={"anton"}
              letterSpacing={1.5}
            >
              The Team
            </Button>
          </div>
          {isMessage && (
            <Alert
              status="success"
              style={{ width: "95%", maxWidth: "1200px" }}
            >
              <AlertIcon />
              Thanks, we have received your contact details and we will get in
              touch!
            </Alert>
          )}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              height: `${(height / 20) * 16}px`,
              //backgroundImage: `url(${stag})`,
              backgroundPosition: "center",
              width: "95%",
              maxWidth: "1200px",
              overflowY: "scroll",
              borderRadius: "3px",
              fontSize: width < 700 ? ".9rem" : "1.3rem",
              textAlign: "justify",
              color: "#B0B0A0",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                // paddingLeft: `${width / 30}px`,
                // paddingRight: `${width / 30}px`,
                paddingTop: "1rem",
                //paddingBottom: "1rem",
                //backgroundColor: "rgba(255,255,255,.7)",
                // borderRadius: "1rem",
                //margin: "1rem",
                width: "100%",
                //maxWidth: "900px",
              }}
            >
              <div>
                <Image
                  src={
                    "https://firebasestorage.googleapis.com/v0/b/nzcountry.appspot.com/o/images%2FJim%20and%20Mark_5.10.1.jpg?alt=media&token=f76138c3-e504-45e9-ac93-b39a67ac60a2"
                  }
                  width={width / 6}
                  style={{ width: width < 700 ? "250px" : `${width / 5}px` }}
                  loading="eager"
                  onClick={() => nav("/inquire")}
                />
              </div>
              <div
                style={{
                  width: "60%",
                  minWidth: "300px",
                  paddingTop: "2rem",
                  paddingBottom: "1rem",
                  textAlign: "center",
                  fontSize: width > 700 ? "2rem" : "1.5rem",

                  fontWeight: "bold",
                }}
              >
                Come hunt with us!
              </div>
              <div
                style={{
                  width: "60%",
                  minWidth: "300px",
                  paddingTop: "2rem",
                  paddingBottom: "4rem",
                  textAlign: "center",
                  fontSize: width > 700 ? "1.3rem" : ".9rem",
                  fontStyle: "italic",
                  fontWeight: "bold",
                }}
              >
                At NZ Country, we specialize in creating some of the best
                hunting stories in the world, ensuring that every trophy hanging
                on the wall comes with an epic tale of adventure, perseverance,
                and triumph.
              </div>
              <div
                style={{
                  width: "40%",
                  minWidth: "300px",
                  //paddingTop: "4rem",
                  //paddingBottom: "4rem",
                  textAlign: "center",
                  fontSize: width > 700 ? "1rem" : ".9rem",
                  //fontStyle: "italic",
                  //fontWeight: "bold",
                }}
              >
                Leave your details so we can get in touch with you about
                tailoring a hunting experience suited perfectly to your
                requirements.
              </div>
              <div
                style={{
                  width: "40%",
                  minWidth: "300px",
                  paddingTop: "1rem",
                  //paddingBottom: "4rem",
                  textAlign: "center",
                  fontSize: width > 700 ? "1rem" : ".9rem",
                  //fontStyle: "italic",
                  //fontWeight: "bold",
                }}
              >
                Or, if you prefer, you can email us at:
              </div>
              <Button
                variant="link"
                size="lg"
                color={"#B0B0A0"}
                onClick={() => window.open("mailto:mark@nzcountry.guide")}
              >
                mark@nzcountry.guide
              </Button>
              <div
                style={{
                  width: "30%",
                  minWidth: "300px",
                  paddingTop: "2rem",
                  paddingBottom: "4rem",
                  textAlign: "justify",
                  fontSize: width > 700 ? "1.3rem" : ".9rem",
                }}
              >
                <Input
                  backgroundColor={"whitesmoke"}
                  style={{ marginBottom: ".25rem" }}
                  color={"#141414"}
                  placeholder="Name"
                  defaultValue={formValues.name}
                  onChange={(e) => {
                    setFormValues((prev: any) => {
                      return { ...prev, name: e.target.value };
                    });
                  }}
                />

                <Input
                  backgroundColor={"whitesmoke"}
                  style={{ marginBottom: ".25rem" }}
                  color={"#141414"}
                  placeholder="Last name"
                  defaultValue={formValues.lastName}
                  onChange={(e) => {
                    setFormValues((prev: any) => {
                      return { ...prev, lastName: e.target.value };
                    });
                  }}
                />
                <Input
                  backgroundColor={"whitesmoke"}
                  style={{ marginBottom: ".25rem" }}
                  color={"#141414"}
                  placeholder="Phone"
                  defaultValue={formValues.phone}
                  onChange={(e) => {
                    setFormValues((prev: any) => {
                      return { ...prev, phone: e.target.value };
                    });
                  }}
                />
                <Input
                  placeholder="Email"
                  backgroundColor={"whitesmoke"}
                  style={{ marginBottom: ".25rem" }}
                  color={"#141414"}
                  defaultValue={formValues.email}
                  onChange={(e) => {
                    setFormValues((prev: any) => {
                      return { ...prev, email: e.target.value };
                    });
                  }}
                />
                <Textarea
                  placeholder="Message"
                  backgroundColor={"whitesmoke"}
                  color={"#141414"}
                  defaultValue={formValues.message}
                  onChange={(e) => {
                    setFormValues((prev: any) => {
                      return { ...prev, message: e.target.value };
                    });
                  }}
                />
              </div>
              <div
                style={{
                  //backgroundColor: "#141414",
                  width: "100%",
                  height: "200px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Button
                  variant="outline"
                  color={"#EA9101"}
                  borderColor={"#EA9101"}
                  mt={1}
                  type="submit"
                  onClick={() => saveForm()}
                >
                  Submit
                </Button>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  height: "400px",
                  backgroundImage: `url(https://firebasestorage.googleapis.com/v0/b/nzcountry.appspot.com/o/images%2FAndy%20300%20WSM_1.21.1.jpg?alt=media&token=f416e0e6-90a9-4899-9d68-0699d1fbf511)`,
                  //backgroundColor: "#141414",
                  backgroundPosition: "center",
                  backgroundSize: "cover",
                  objectFit: "contain",
                  width: "100%",
                  maxWidth: "1200px",
                  paddingTop: "2rem",
                  paddingBottom: "3rem",
                }}
              >
                <div>
                  <Image
                    src={logo}
                    width={width / 6}
                    style={{ width: width < 700 ? "250px" : `${width / 7}px` }}
                    loading="eager"
                    onClick={() => nav("/inquire")}
                  />
                </div>
                <Button
                  variant="outline"
                  onClick={() => nav("/")}
                  color={"#EA9101"}
                  borderColor={"#EA9101"}
                >
                  Home
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
