import { Button, Icon, Image } from "@chakra-ui/react";
import { logEvent } from "firebase/analytics";
import { doc, getDoc } from "firebase/firestore";
import { useEffect, useState } from "react";
import { AiFillSound } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { analytics, firestore } from "../firebase";
import { windowSizeState } from "../recoil";
import hunter from "./../About/Hunter.jpg";
import sight from "./../About/Sight_cropped.jpg";
import logo from "./../Home/nz country.png";
import { Helmet } from "react-helmet";

export const OutReach = () => {
  const nav = useNavigate();

  useEffect(() => {
    logEvent(analytics, "page_view", { page_title: "Outreach" });
  }, []);
  const [images, setImages] = useState<any>(null);
  useEffect(() => {
    getDoc(doc(firestore, "siteImages/yhp"))
      .then((snapDoc) => {
        setImages(() => ({ ...snapDoc.data() }));
      })
      .catch((err) => console.error("Error loading images"));
  }, []);

  const schema = {
    "@context": "https://schema.org",
    "@type": "Event",
    name: "NZ Country Young Hunters Program",
    description:
      "A program designed to teach kids about firearm safety, ethical hunting, stalking, animal recovery, butchery and bushcraft. The course culminates with the participant achieving their first deer harvesting experience.",
    url: "https://www.nzcountry.guide/outreach",
    startDate: "2025-01-01",
    endDate: "2025-12-31",
    eventAttendanceMode: "https://schema.org/OfflineEventAttendanceMode",
    eventStatus: "https://schema.org/EventScheduled",
    location: {
      "@type": "Place",
      name: "NZ COUNTRY",
      address: {
        "@type": "PostalAddress",
        addressLocality: "Haupiri",
        addressRegion: "West Coast",
        addressCountry: "NZ",
      },
    },
    organizer: {
      "@type": "NZ COUNTRY",
      name: "NZ Country Young Hunters Program",
      url: "https://www.nzcountry.guide",
      logo: "https://firebasestorage.googleapis.com/v0/b/nzcountry.appspot.com/o/partners%2FNZCOUNTRY.jpg?alt=media&token=8fb33668-9a56-4a9f-9800-5b01d4e3eb0a",
      contactPoint: {
        "@type": "ContactPoint",
        email: "+1-800-555-5555",
        contactType: "Customer Service",
        areaServed: "NZ",
        availableLanguage: ["English"],
      },
    },
    offers: {
      "@type": "Offer",
      url: "https://www.nzcountry.guide/inquire",
      price: "Free",
      availability: "https://schema.org/LimitedAvailability",
      validFrom: "2025-01-01",
    },
    performer: {
      "@type": "Person",
      name: "Mark Lupi",
      jobTitle:
        "Business Owner, Professional Hunting Guide and Firearms Expert",
      worksFor: {
        "@type": "Organization",
        name: "NZ COUNTRY",
      },
      url: "https://www.nzcountry.guide/team",
      image:
        "https://firebasestorage.googleapis.com/v0/b/nzcountry.appspot.com/o/teamImages%2FMark%20Sq.jpg?alt=media&token=c27442ed-8c6d-4839-bffb-70cf543b82f9",
      email: "mailto:mark@nzcountry.guide",
    },
    image: [
      "https://firebasestorage.googleapis.com/v0/b/nzcountry.appspot.com/o/images%2FChamois%20YHP.jpg?alt=media&token=23878d43-9c6c-49ab-b457-209108469180",
      "https://firebasestorage.googleapis.com/v0/b/nzcountry.appspot.com/o/images%2FDeer%203%20YHP.jpg?alt=media&token=25fbee6a-8f36-4faa-bb05-f36418385626",
    ],
  };

  const { width, height } = useRecoilValue(windowSizeState);

  return (
    <>
      <Helmet>
        <script type="application/ld+json">{JSON.stringify(schema)}</script>
      </Helmet>
      <div
        style={{
          height: "100vh",
          overflowY: "hidden",
          backgroundColor: "rgba(0,0,0,.9)",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            paddingTop: `${height / 20}px`,
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              height: `${(height / 28) * 3}px`,
              width: "95%",
              maxWidth: "1200px",
              color: "#EA9101",
              fontFamily: "anton",
              fontSize: width < 550 ? "30px" : `${width / 30}px`,
              borderRadius: "3px",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                height: "100%",
                color: "#EA9101",
              }}
            >
              COMMUNITY OUTREACH
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              width: "90%",
              maxWidth: "400px",
              minHeight: "40px",
              justifyContent: "space-between",
            }}
          >
            <Button
              variant="link"
              size="lg"
              color={"#B0B0A0"}
              onClick={() => nav("/")}
              fontFamily={"anton"}
              letterSpacing={1.5}
            >
              Home
            </Button>
            <Button
              variant="link"
              size="lg"
              color={"#B0B0A0"}
              onClick={() => nav("/trophy-Hunting")}
              fontFamily={"anton"}
              letterSpacing={1.5}
            >
              Trophy Hunts
            </Button>
            <Button
              variant="link"
              size="lg"
              color={"#B0B0A0"}
              onClick={() => nav("/team")}
              fontFamily={"anton"}
              letterSpacing={1.5}
            >
              The Team
            </Button>
            {/* {isSound ? (
            <IconButton
              variant={"link"}
              icon={<Speaker />}
              aria-label="Stop"
              onClick={() => {
                setIsSound(false);
                sound.current.pause();
              }}
            />
          ) : (
            <IconButton
              variant={"link"}
              icon={<Speaker />}
              aria-label="Play"
              onClick={() => {
                setIsSound(true);
                sound.current.play();
              }}
            />
          )} */}
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              height: `${(height / 20) * 16}px`,
              backgroundPosition: "center",
              width: "95%",
              maxWidth: "1200px",
              overflowY: "scroll",
              borderRadius: "3px",
              fontSize: width < 700 ? ".9rem" : "1.3rem",
              textAlign: "justify",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                paddingTop: "1rem",
                color: "#B0B0A0",
                width: "100%",
              }}
            >
              <div
                style={{
                  width: "80%",
                  paddingTop: "5rem",
                  paddingBottom: "2rem",
                  display: "flex",
                  flexDirection: width > 1000 ? "row" : "column",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    width: "100%",
                    paddingRight: width > 1000 ? "5rem" : "0rem",
                  }}
                >
                  New Zealand Country Ltd has a solid commitment to community
                  growth. This is best reflected in the outreach program,
                  facilitated by the NZ Country team, and funded by NZ Country
                  clients. Over a two-day course designed for young hunters, the
                  experience is focused on learning about basic bushcraft,
                  ethical hunting methods, and respect for the environment. The
                  desired outcome of the course is that the young person
                  finishes the course with an increased level of confidence and
                  an appreciation of the sustainability hunting can provide in
                  terms of controlling animal numbers, whilst providing quality
                  food for themselves and their family.
                </div>
                {images && (
                  <div
                    style={{
                      marginTop: "1rem",
                      backgroundImage: `url(${images.introPhoto})`,
                      backgroundPosition: "center",
                      backgroundSize: "cover",
                      objectFit: "contain",
                      color: "#EA9101",
                      width: "100%",
                      height: "100%",
                      minHeight: "250px",
                      textAlign: "center",
                      borderRadius: ".5rem",
                    }}
                  ></div>
                )}
              </div>
              <div
                style={{
                  width: "80%",
                  paddingTop: "5rem",
                  paddingBottom: "2.5rem",
                }}
              >
                The NZ Country Young Hunters program covers important topics
                such as firearm safety, ethical hunting, stalking, animal
                recovery, butchery and bushcraft. The course culminates with the
                participant achieving their first deer harvesting experience.
              </div>
              <div
                style={{
                  width: "80%",
                  paddingTop: "5rem",
                  paddingBottom: "2rem",
                  display: "flex",
                  flexDirection: width > 1000 ? "row" : "column",
                  alignItems: "center",
                }}
              >
                {images && (
                  <div
                    style={{
                      paddingTop: "2rem",
                      backgroundImage: `url(${images.secondPhoto})`,
                      backgroundPosition: "center",
                      backgroundSize: "cover",
                      objectFit: "contain",
                      color: "#EA9101",
                      width: "100%",
                      height: "100%",
                      minHeight: "200px",
                      textAlign: "center",
                      borderRadius: ".5rem",
                    }}
                  ></div>
                )}
                <div
                  style={{
                    width: width > 1000 ? "80%" : "100%",
                    paddingLeft: width > 1000 ? "5rem" : "0rem",
                  }}
                >
                  With the increased urbanisation of the modern world, and
                  reliance on convenient forms of food distribution, young
                  people today are often disadvantaged when it comes to learning
                  skills of self-reliance and experiencing what the natural
                  world has to offer. This learning experience is provided for
                  young aspirational hunters who would normally never get the
                  chance to try hunting for themselves, often due to a lack of
                  access to the hunting world, and the challenges and rewards of
                  harvesting free-range meat.
                </div>
              </div>
              <div
                style={{
                  width: "60%",
                  paddingTop: "5rem",
                  paddingBottom: "2.5rem",
                  fontStyle: "italic",
                  textAlign: "center",
                  color: "#EA9101",
                }}
              >
                “This amazing country has empowered me over the years. Choose to
                hunt with NZ Country and help me enable the next generation of
                young New Zealand hunters”
              </div>

              <div
                style={{
                  width: "80%",
                  paddingTop: "5rem",
                  paddingBottom: "2rem",
                  display: "flex",
                  flexDirection: width > 1000 ? "row" : "column",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    width: width > 1000 ? "80%" : "100%",
                    paddingRight: width > 1000 ? "5rem" : "0rem",
                  }}
                >
                  <div>
                    <b>Our Partnership</b>
                  </div>
                  <div>
                    The NZ Country Young Hunters program is funded solely by
                    hunting clients who book NZ Country hunting experiences.
                  </div>
                </div>
                <div
                  style={{
                    paddingTop: "2rem",
                    backgroundImage: `url(${hunter})`,
                    backgroundPosition: "center",
                    backgroundSize: "cover",
                    objectFit: "contain",
                    color: "#EA9101",
                    width: "100%",
                    height: "100%",
                    minHeight: "200px",
                    textAlign: "center",
                    borderRadius: ".5rem",
                  }}
                ></div>
              </div>
              <div
                style={{
                  width: "80%",
                  paddingTop: "5rem",
                  paddingBottom: "5rem",
                }}
              >
                <div>
                  <b>Passing on Skills</b>
                </div>
                <div>
                  The NZ Country Young Hunters program is facilitated by Mark
                  Lupi, the founder of NZ Country. Mark is a decorated New
                  Zealand military veteran, who has won multiple awards for
                  combat shooting competitions, including the prestigious
                  Queen's Medal. His proficiency in firearms instruction has
                  enabled Mark to be a valued member of the NZ Police firearms
                  vetting team as a Firearms Safety Officer. Additionally, Mark
                  has run high-level sports coaching for youths and is dedicated
                  to seeing the development of positive character and attributes
                  within the youth of today's society.
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  paddingTop: "2rem",
                  backgroundImage: `url(${sight})`,
                  backgroundPosition: "center",
                  backgroundSize: "cover",
                  objectFit: "contain",
                  color: "#EA9101",
                  width: "100%",
                  height: "30vh",
                  textAlign: "center",
                }}
              >
                {" "}
                <div>
                  <Image
                    src={logo}
                    style={{
                      width: width < 700 ? "250px" : `${width / 7}px`,
                      maxWidth: "150px",
                    }}
                    loading="eager"
                    onClick={() => nav("/inquire")}
                  />
                </div>
                <Button
                  variant="outline"
                  onClick={() => nav("/inquire")}
                  color={"#EA9101"}
                  borderColor={"#EA9101"}
                >
                  Come hunt with us - Inquire
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
