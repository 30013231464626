import { Button, Icon, Image } from "@chakra-ui/react";
import { logEvent } from "firebase/analytics";
import { doc, getDoc } from "firebase/firestore";
import { useEffect, useState } from "react";
import { AiFillSound } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { analytics, firestore } from "../firebase";
import { windowSizeState } from "../recoil";
import logo from "./../Home/nz country.png";
import lodge from "./Hunting Lodges_1.3.1.jpg";
import { Helmet } from "react-helmet";

const Speaker = () => {
  return <Icon as={AiFillSound} color={"#EA9101"} />;
};

export const Fallow = () => {
  const nav = useNavigate();

  useEffect(() => {
    logEvent(analytics, "page_view", { page_title: "Fallow" });
  }, []);

  const [images, setImages] = useState<any>(null);
  useEffect(() => {
    getDoc(doc(firestore, "siteImages/fallow"))
      .then((snapDoc) => {
        setImages(() => ({ ...snapDoc.data() }));
      })
      .catch((err) => console.error("Error loading images"));
  }, []);

  const schema = {
    "@context": "https://schema.org",
    "@type": "Product",
    name: "Guided Fallow Deer Trophy Hunting in New Zealand",
    image:
      "https://firebasestorage.googleapis.com/v0/b/nzcountry.appspot.com/o/images%2FFallow_2.5.1.jpg?alt=media&token=abb9ff3d-e118-41f4-8040-926ca77fb022",
    description:
      "Guided fallow deer hunting in New Zealand. Hunt for fallow deer in New Zealand's beautiful West Coast wilderness area.",
    brand: {
      "@type": "Brand",
      name: "NZ COUNTRY",
    },
    offers: {
      "@type": "Offer",
      price: "Starting from $6000",
      priceCurrency: "USD",
      availability: "https://schema.org/InStock",
    },
  };

  const { width, height } = useRecoilValue(windowSizeState);

  return (
    <>
      <Helmet>
        <script type="application/ld+json">{JSON.stringify(schema)}</script>
      </Helmet>
      <div
        style={{
          height: "100vh",
          overflowY: "hidden",
          backgroundColor: "rgba(0,0,0,.9)",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            paddingTop: `${height / 20}px`,
            //paddingBottom: `${height / 20}px`,
            //overflowY: "scroll",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              height: `${(height / 28) * 3}px`,
              width: "95%",
              maxWidth: "1200px",
              color: "#EA9101",
              fontFamily: "anton",
              fontSize: width < 550 ? "30px" : `${width / 30}px`,
              borderRadius: "3px",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                height: "100%",
              }}
            >
              FALLOW BUCKS
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              width: "90%",
              maxWidth: "400px",
              minHeight: "40px",
              justifyContent: "space-between",
            }}
          >
            <Button
              variant="link"
              size="lg"
              color={"#B0B0A0"}
              onClick={() => nav("/")}
              fontFamily={"anton"}
              letterSpacing={1.5}
            >
              Home
            </Button>
            <Button
              variant="link"
              size="lg"
              color={"#B0B0A0"}
              onClick={() => nav("/trophy-Hunting")}
              fontFamily={"anton"}
              letterSpacing={1.5}
            >
              Trophy Hunts
            </Button>
            <Button
              variant="link"
              size="lg"
              color={"#B0B0A0"}
              onClick={() => nav("/team")}
              fontFamily={"anton"}
              letterSpacing={1.5}
            >
              The Team
            </Button>
            {/* {isSound ? (
            <IconButton
              variant={"link"}
              icon={<Speaker />}
              aria-label="Stop"
              onClick={() => {
                setIsSound(false);
                sound.current.pause();
              }}
            />
          ) : (
            <IconButton
              variant={"link"}
              icon={<Speaker />}
              aria-label="Play"
              onClick={() => {
                setIsSound(true);
                sound.current.play();
              }}
            />
          )} */}
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              height: `${(height / 20) * 16}px`,
              backgroundPosition: "center",
              width: "95%",
              maxWidth: "1200px",
              overflowY: "scroll",
              borderRadius: "3px",
              fontSize: width < 700 ? ".9rem" : "1.3rem",
              textAlign: "justify",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                paddingTop: "1rem",
                paddingBottom: "1rem",
                width: "100%",
                color: "#B0B0A0",
              }}
            >
              {" "}
              <div
                style={{
                  width: "80%",
                  paddingTop: "5rem",
                  paddingBottom: "2rem",
                  display: "flex",
                  flexDirection: width > 1000 ? "row" : "column",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    width: "100%",
                    paddingRight: width > 1000 ? "5rem" : "0rem",
                  }}
                >
                  Known for their strikingly beautiful coats, which vary from
                  white and spotted to rich brown, these deer are a prized game
                  species for trophy hunters. Their graceful movements and keen
                  senses make them a challenging and rewarding target, while
                  their presence contributes to New Zealand's rich biodiversity.
                </div>
                {images && (
                  <div
                    style={{
                      marginTop: "1rem",
                      backgroundImage: `url(${images.firstImage})`,
                      backgroundPosition: "center",
                      backgroundSize: "cover",
                      objectFit: "contain",
                      color: "#EA9101",
                      width: "100%",
                      height: "100%",
                      minHeight: "250px",
                      textAlign: "center",
                      borderRadius: ".5rem",
                    }}
                  ></div>
                )}
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  //height: "100vh",
                  width: "100%",
                  maxWidth: "1200px",
                  paddingTop: "6rem",
                  paddingBottom: "3rem",
                }}
              >
                <Button
                  variant={"outline"}
                  color={"white"}
                  onClick={() => nav("/packages")}
                >
                  Click to use our pricing calculator
                </Button>
              </div>
              <div
                style={{
                  width: "60%",
                  paddingTop: "4rem",
                  paddingBottom: "4rem",
                  fontStyle: "italic",
                  fontWeight: "bold",
                  color: "#EA9101",
                  textAlign: "center",
                }}
              >
                "This whole hunt has been fantastic. Mark and his guides have
                just been awesome!", Jim Eff - Red Deer Hunting
              </div>
              <div
                style={{
                  width: "80%",
                  paddingTop: "5rem",
                  paddingBottom: "2rem",
                  display: "flex",
                  flexDirection: width > 1000 ? "row" : "column",
                  alignItems: "center",
                }}
              >
                {images && (
                  <div
                    style={{
                      paddingTop: "2rem",
                      backgroundImage: `url(${images.secondImage})`,
                      backgroundPosition: "center",
                      backgroundSize: "cover",
                      objectFit: "contain",
                      color: "#EA9101",
                      width: "100%",
                      height: "100%",
                      minHeight: "200px",
                      textAlign: "center",
                      borderRadius: ".5rem",
                    }}
                  ></div>
                )}
                <div
                  style={{
                    width: width > 1000 ? "80%" : "100%",
                    paddingLeft: width > 1000 ? "5rem" : "0rem",
                  }}
                >
                  At NZ COUNTRY we aim to provide an unforgettable fallow buck
                  hunting experience. Combine the thrill of the hunt with the
                  beauty of New Zealand’s West Coast landscapes, from dense
                  forests to open grasslands. This hunt is a hunt for those who
                  want a rewarding challenge.
                </div>
              </div>
              <div
                style={{
                  width: "60%",
                  paddingTop: "4rem",
                  paddingBottom: "4rem",
                  fontStyle: "italic",
                  fontWeight: "bold",
                  color: "#EA9101",
                  textAlign: "center",
                }}
              >
                Our guides bring a love for the hunt on every expedition as well
                as a promise of unmatched excitement and success.
              </div>
              <div
                style={{
                  width: "80%",
                  paddingTop: "4rem",
                  paddingBottom: "4rem",
                }}
              >
                Our two secluded hunting lodges offer the perfect retreat after
                a day of adventure, nestled deep within the serene landscapes.
                Each lodge provides a cozy and tranquil atmosphere, allowing you
                to unwind and rejuvenate in complete privacy while enjoying
                top-notch amenities.
              </div>
              <div style={{ paddingTop: "2rem" }}>
                <Image src={lodge} />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  backgroundColor: "#141414",
                  padding: "3rem",
                }}
              >
                <div
                  style={{
                    padding: "3rem",
                    fontWeight: "bold",
                    fontSize: width < 800 ? "1.2rem" : "2rem",
                    color: "#EA9101",
                    textAlign: "center",
                  }}
                >
                  NZ COUNTRY also offers multi-animal packages for the ultimate
                  hunting experience.
                </div>{" "}
                <Button
                  variant={"outline"}
                  color={"white"}
                  onClick={() => nav("/packages")}
                >
                  Click to see our pricing
                </Button>
              </div>
              {/* <div
              style={{
                paddingTop: "2rem",
                fontWeight: "bold",
                fontSize: "2rem",
              }}
            >
              Red Stag Gallery
            </div>
            <div
              style={{
                paddingTop: "1rem",
                paddingBottom: "2rem",
                width: "100%",
              }}
            >
              {images && <Gallery images={images.gallery} />}
            </div> */}
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                height: "100vh",
                backgroundImage: `url(https://firebasestorage.googleapis.com/v0/b/nzcountry.appspot.com/o/images%2FAndy%20300%20WSM_1.21.1.jpg?alt=media&token=f416e0e6-90a9-4899-9d68-0699d1fbf511)`,
                //backgroundColor: "#141414",
                backgroundPosition: "center",
                backgroundSize: "cover",
                objectFit: "contain",
                width: "100%",
                maxWidth: "1200px",
                paddingTop: "2rem",
                paddingBottom: "3rem",
              }}
            >
              <div>
                <Image
                  src={logo}
                  width={width / 6}
                  style={{ width: width < 700 ? "250px" : `${width / 7}px` }}
                  loading="eager"
                  onClick={() => nav("/inquire")}
                />
              </div>
              <Button
                variant="outline"
                onClick={() => nav("/inquire")}
                color={"#EA9101"}
                borderColor={"#EA9101"}
              >
                Come hunt with us - Inquire
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
