import { Button, Image } from "@chakra-ui/react";
import { logEvent } from "firebase/analytics";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { analytics } from "../firebase";
import { windowSizeState } from "../recoil";
import { Helmet } from "react-helmet";
import deer from "./nz country.png";

export const Home = () => {
  const { width } = useRecoilValue(windowSizeState);
  //const [isSound, setIsSound] = useRecoilState(soundState);
  const nav = useNavigate();
  useEffect(() => {
    logEvent(analytics, "page_view", { page_title: "Home" });
  }, []);

  return (
    <>
      <Helmet>
        <title>NZ COUNTRY</title>
        <meta
          name="description"
          content="NZ COUNTRY premium trophy hunting in New Zealand. Guided hunting for trophy deer, fallow deer, goat, thar, and arapawa rams."
        />
        <meta
          name="keywords"
          content="Trophy, Hunting, NZ COUNTRY, Thar, Fallow, Rams, Deer, Goat, Arapawa, guide"
        />
        <meta property="og:title" content="NZ COUNTRY" />
        <meta
          property="og:description"
          content="NZ COUNTRY premium trophy hunting and accommodation in New Zealand. Guided hunting for trophy deer, fallow deer, goat, thar, and arapawa rams."
        />
        <meta property="og:url" content="https://www.nzcountry.guide" />
        <meta property="og:type" content="website" />
      </Helmet>
      <div style={{ height: "100vh", overflowY: "hidden" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              height: "100vh",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Image
                src={deer}
                width={width / 4}
                style={{ width: width < 700 ? "150px" : `${width / 4}px` }}
                loading="eager"
                onClick={() => {
                  logEvent(analytics, "select_item", {
                    item_list_name: "home to outreach",
                  });
                  nav("/trophy-hunting");
                }}
              />
              <div
                style={{
                  display: "flex",
                  flexDirection: "column", //width < 930 ? "column" : "row"
                  alignItems: "center",
                  width: "90vw",
                  maxWidth: "500px",
                  justifyContent: "space-evenly",
                }}
              >
                <Button
                  variant="link"
                  size="lg"
                  color={"#EA9101"}
                  onClick={() => {
                    logEvent(analytics, "select_item", {
                      item_list_name: "home to trophy hunting",
                    });
                    nav("/trophy-hunting");
                  }}
                  fontFamily={"anton"}
                  fontSize={width < 800 ? "1.3rem" : "2rem"}
                  letterSpacing={1.5}
                >
                  Trophy Hunting
                </Button>
                <Button
                  variant="link"
                  size="lg"
                  color={"#EA9101"} //{"#B0B0A0"}
                  onClick={() => {
                    logEvent(analytics, "select_item", {
                      item_list_name: "home to outreach",
                    });
                    nav("/outreach");
                  }}
                  fontFamily={"anton"}
                  fontSize={width < 800 ? "1.3rem" : "2rem"}
                  letterSpacing={1.5}
                >
                  Community Outreach
                </Button>
                <Button
                  variant="link"
                  size="lg"
                  color={"#EA9101"}
                  onClick={() => {
                    logEvent(analytics, "select_item", {
                      item_list_name: "home to packages",
                    });
                    nav("/packages");
                  }}
                  fontFamily={"anton"}
                  fontSize={width < 800 ? "1.3rem" : "2rem"}
                  letterSpacing={1.5}
                >
                  Pricing
                </Button>
                <Button
                  variant="link"
                  size="lg"
                  color={"#EA9101"}
                  fontSize={width < 800 ? "1.3rem" : "2rem"}
                  onClick={() => {
                    logEvent(analytics, "select_item", {
                      item_list_name: "home to about",
                    });
                    nav("/about");
                  }}
                  fontFamily={"anton"}
                  letterSpacing={1.5}
                >
                  About
                </Button>
                <Button
                  variant="link"
                  size="lg"
                  color={"#EA9101"} //{"#B0B0A0"}
                  onClick={() => {
                    logEvent(analytics, "select_item", {
                      item_list_name: "home to inquire",
                    });
                    nav("/inquire");
                  }}
                  fontFamily={"anton"}
                  fontSize={width < 800 ? "1.3rem" : "2rem"}
                  letterSpacing={1.5}
                >
                  Contact
                </Button>
              </div>
              <div style={{ padding: "1rem" }}>
                {/* <Button
                variant="link"
                size="sm"
                color={"#EA9101"}
                onClick={() => (isSound ? setIsSound(false) : setIsSound(true))}
              >
                {isSound ? "Turn sound OFF" : "Turn sound ON"}
              </Button> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
