import { Button, Icon, Image } from "@chakra-ui/react";
import { logEvent } from "firebase/analytics";
import { collection, onSnapshot } from "firebase/firestore";
import { useEffect, useState } from "react";
import { AiFillSound } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import YouTube from "react-youtube";
import { useRecoilValue } from "recoil";
import { analytics, firestore } from "../firebase";
import { windowSizeState } from "../recoil";
import { MemberInfo } from "../types/typeDefs";
import logo from "./../Home/nz country.png";
import hunters from "./Hunters_2.14.1.jpg";
import sight from "./Sight_cropped.jpg";
import { Helmet } from "react-helmet";

const Speaker = () => {
  return <Icon as={AiFillSound} color={"#EA9101"} />;
};

export const About = () => {
  const nav = useNavigate();

  useEffect(() => {
    logEvent(analytics, "page_view", { page_title: "About" });
  }, []);

  const [teamMembers, setTeamMembers] = useState<Array<MemberInfo>>([]);

  useEffect(() => {
    const unsub = onSnapshot(collection(firestore, `team`), (snapshot) => {
      setTeamMembers([]);
      snapshot.forEach((snapDoc) => {
        setTeamMembers((prev) => {
          return [...prev, { ...snapDoc.data(), id: snapDoc.id } as MemberInfo];
        });
      });
    });
    return unsub;
  }, []);

  const { width, height } = useRecoilValue(windowSizeState);

  const schema = {
    "@context": "https://schema.org",
    "@type": "Organization",
    name: "NZ COUNTRY",
    url: "https://www.nzcountry.guide",
    logo: "https://firebasestorage.googleapis.com/v0/b/nzcountry.appspot.com/o/partners%2FNZCOUNTRY.jpg?alt=media&token=8fb33668-9a56-4a9f-9800-5b01d4e3eb0a",
    contactPoint: {
      "@type": "ContactPoint",
      email: "mailto:mark@nzcountry.guide",
      contactType: "customer service",
    },
    video: {
      "@type": "VideoObject",
      name: "See a short video of NZ COUNTRY in 2024 out on a guided hunt for red deer and tahr in New Zealand.",
      description:
        "A short video introducing a NZ COUNTRY guided hunt. In this video our professional hunting guides take some clients in to the wild to find some red deer and tahr in New Zealand.",
      thumbnailUrl:
        "https://firebasestorage.googleapis.com/v0/b/nzcountry.appspot.com/o/teamImages%2Fethan_2.73.1.jpg?alt=media&token=e8078d74-7fbe-4446-8dbc-965d18f0e326",
      uploadDate: "2024-06-26",
      contentUrl: "https://youtu.be/TcY-hQwJL_o?si=JlpKByYpkaRyVtc1",
      embedUrl: "https://www.youtube.com/embed/TcY-hQwJL_o?si=JlpKByYpkaRyVtc1",
      duration: "5:49",
      publisher: {
        "@type": "Organization",
        name: "SCRYPTWORX STUDIOS",
        logo: {
          "@type": "ImageObject",
          url: "https://firebasestorage.googleapis.com/v0/b/nzcountry.appspot.com/o/partners%2FSWS.jpg?alt=media&token=a37da071-5593-4d17-abfe-c88a29b0fcb9",
        },
      },
    },
  };

  return (
    <>
      <Helmet>
        <script type="application/ld+json">{JSON.stringify(schema)}</script>
      </Helmet>
      <div
        style={{
          height: "100vh",
          overflowY: "hidden",
          backgroundColor: "rgba(0,0,0,.9)",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            paddingTop: `${height / 20}px`,
            //paddingBottom: `${height / 20}px`,
            //overflowY: "scroll",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              height: `${(height / 28) * 3}px`,
              width: "95%",
              maxWidth: "1200px",

              color: "#EA9101",
              fontFamily: "anton",
              fontSize: width < 550 ? "30px" : `${width / 30}px`,
              borderRadius: "3px",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                height: "100%",
                color: "#EA9101",
              }}
            >
              ABOUT
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              width: "90%",
              maxWidth: "400px",
              minHeight: "40px",
              justifyContent: "space-between",
            }}
          >
            <Button
              variant="link"
              size="lg"
              color={"#B0B0A0"}
              onClick={() => nav("/")}
              fontFamily={"anton"}
              letterSpacing={1.5}
            >
              Home
            </Button>
            <Button
              variant="link"
              size="lg"
              color={"#B0B0A0"}
              onClick={() => nav("/trophy-Hunting")}
              fontFamily={"anton"}
              letterSpacing={1.5}
            >
              Trophy Hunts
            </Button>
            <Button
              variant="link"
              size="lg"
              color={"#B0B0A0"}
              onClick={() => {
                logEvent(analytics, "select_item", {
                  item_list_name: "about to team",
                });
                nav("/team");
              }}
              fontFamily={"anton"}
              letterSpacing={1.5}
            >
              The Team
            </Button>
            {/* {isSound ? (
            <IconButton
              variant={"link"}
              icon={<Speaker />}
              aria-label="Stop"
              onClick={() => {
                setIsSound(false);
                sound.current.pause();
              }}
            />
          ) : (
            <IconButton
              variant={"link"}
              icon={<Speaker />}
              aria-label="Play"
              onClick={() => {
                setIsSound(true);
                sound.current.play();
              }}
            />
          )} */}
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              height: `${(height / 20) * 16}px`,
              //backgroundColor: "whitesmoke",
              // backgroundImage: `url(${stag})`,
              backgroundPosition: "center",
              //objectPosition: "center",
              width: "95%",
              maxWidth: "1200px",
              //padding: "2rem",
              overflowY: "scroll",
              borderRadius: "3px",
              fontSize: width < 700 ? ".9rem" : "1.3rem",
              textAlign: "justify",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                paddingTop: "1rem",
                //backgroundColor: "rgba(255,255,255,.7)",
                color: "#B0B0A0",
                width: "100%",
              }}
            >
              {" "}
              <div
                style={{
                  width: "80%",
                  paddingTop: "5rem",
                  paddingBottom: "2rem",
                  display: "flex",
                  flexDirection: width > 1000 ? "row" : "column",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    width: "100%",
                    paddingRight: width > 1000 ? "5rem" : "0rem",
                  }}
                >
                  NZ COUNTRY is your premier destination for world-class hunting
                  adventures on the breathtaking West Coast of New Zealand. Our
                  company prides itself on offering unforgettable experiences,
                  combining the thrill of the hunt with the comfort of luxury
                  accommodations. Nestled in the dense native bush, our two
                  secluded 5-star hunting lodges provide the perfect retreat for
                  discerning hunters seeking both adventure and relaxation.
                </div>
                <div
                  style={{
                    paddingTop: "2rem",
                    backgroundImage: `url(${hunters})`,
                    backgroundPosition: "center",
                    backgroundSize: "cover",
                    objectFit: "contain",
                    color: "#EA9101",
                    width: "100%",
                    height: "100%",
                    minHeight: "200px",
                    textAlign: "center",
                    borderRadius: ".5rem",
                  }}
                ></div>
              </div>
              <div
                style={{
                  width: "80%",
                  paddingTop: "5rem",
                  paddingBottom: "5rem",
                  fontStyle: "italic",
                  fontWeight: "bold",
                  color: "#EA9101",
                  textAlign: "center",
                }}
              >
                At NZ COUNTRY, we specialize in hunting trips for Trophy Red
                Deer Stags, Thar, Chamois, Fallow Deer, Awapara Rams, and Trophy
                Goats.
              </div>
              <YouTube
                videoId="TcY-hQwJL_o?si=o3mlJTSawTY0Xity"
                opts={{
                  height:
                    width < 650 ? (width / 19) * 9 : ((width * 0.51) / 16) * 9,
                  width: width < 650 ? width : width * 0.51,
                  origin: "http://localhost:3000",
                }}
                onEnd={(e: any) => {
                  console.log(e);
                }}
                onStateChange={(e: any) => console.log("Change")}
                onPlay={(e: any) => console.log("Play")}
              />
              {/* <iframe
              ref={vidRef}
              width={width < 650 ? width : width * 0.51}
              height={
                width < 650 ? (width / 19) * 9 : ((width * 0.51) / 16) * 9
              }
              src="https://www.youtube.com/embed/TcY-hQwJL_o?si=o3mlJTSawTY0Xity?rel=0"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerPolicy="strict-origin-when-cross-origin"
              allowFullScreen
            ></iframe> */}
              <div
                style={{
                  width: "80%",
                  paddingTop: "8rem",
                  //paddingBottom: "5rem",
                  fontStyle: "italic",
                  fontWeight: "bold",
                  color: "#EA9101",
                  textAlign: "center",
                }}
              >
                Our Professional Hunting Guides
              </div>
              <div
                style={{
                  width: "80%",
                  display: "flex",
                  flexDirection: width < 750 ? "column" : "row",
                  alignItems: "center",
                  justifyContent: "space-evenly",
                  paddingTop: "5rem",
                  paddingBottom: "2rem",
                }}
              >
                {teamMembers &&
                  teamMembers.map((member, idx) => {
                    return (
                      <Image
                        key={idx}
                        //width={(width * 0.8) / 6}
                        boxSize={width < 750 ? 200 : (width * 0.8) / 8}
                        src={member.squareImage}
                        onClick={() => {
                          logEvent(analytics, "select_item", {
                            item_list_name: member.name,
                          });
                          nav("/team");
                        }}
                        style={{ cursor: "pointer" }}
                      />
                    );
                  })}
              </div>
              <div
                style={{
                  width: "80%",

                  paddingBottom: "2rem",
                }}
              >
                On a hunt, you will be assisted by our expert guides who have
                years of experience with firearms and hunting alike. With half
                of the team being military veterans, we take pride in our
                military grade shooting coaching and bushcraft skills. Our
                guides will lead you on exhilarating hunts through some of the
                most challenging and scenic terrains in New Zealand. Whether you
                are stalking through the lush undergrowth or navigating rugged
                mountain passes, each hunt promises excitement, challenge, and
                the opportunity to take home a coveted trophy.
              </div>
              <Button
                variant="outline"
                onClick={() => nav("/team")}
                color={"#EA9101"}
                borderColor={"#EA9101"}
              >
                Learn more about our guides
              </Button>
              <div
                style={{
                  width: "80%",
                  paddingTop: "5rem",
                  paddingBottom: "2rem",
                  display: "flex",
                  flexDirection: width > 1000 ? "row" : "column",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    paddingTop: "2rem",
                    backgroundImage: `url(https://firebasestorage.googleapis.com/v0/b/nzcountry.appspot.com/o/images%2FCarry%20Out_2.21.1.jpg?alt=media&token=61d42d3c-565e-44f4-84f9-1cd5d95d30a5)`,
                    backgroundPosition: "center",
                    backgroundSize: "cover",
                    objectFit: "contain",
                    color: "#EA9101",
                    width: "100%",
                    height: "100%",
                    minHeight: "200px",
                    textAlign: "center",
                    borderRadius: ".5rem",
                  }}
                ></div>
                <div
                  style={{
                    width: width > 1000 ? "80%" : "100%",
                    // paddingTop: "5rem",
                    // paddingBottom: "5rem",
                    paddingLeft: width > 1000 ? "5rem" : "0rem",
                  }}
                >
                  In addition to our exceptional hunting opportunities, we offer
                  a range of premium services to enhance your adventure.
                  Experience the thrill of a helicopter ride over the stunning
                  landscapes of the West Coast, providing access to remote
                  hunting grounds and breathtaking aerial views. For those
                  looking to improve their marksmanship, our long-range shooting
                  and range work sessions are led by skilled instructors who
                  will help you perfect your technique. And for those who wish
                  to capture the beauty of their journey, our photography
                  services ensure that every moment is immortalized in stunning
                  detail.
                </div>
              </div>
              <div
                style={{
                  width: "80%",
                  paddingTop: "5rem",
                  paddingBottom: "2rem",
                  fontStyle: "italic",
                  fontWeight: "bold",
                  color: "#EA9101",
                  textAlign: "center",
                }}
              >
                At NZ COUNTRY, we also partner with some of the best in the
                business.
              </div>
              <div
                style={{
                  width: "80%",
                  display: "flex",
                  flexDirection: width < 750 ? "column" : "row",
                  alignItems: "center",
                  justifyContent: "space-evenly",
                  paddingTop: "1rem",
                  paddingBottom: "8rem",
                }}
              >
                <Image
                  //width={(width * 0.8) / 6}
                  boxSize={width < 750 ? 200 : (width * 0.8) / 8}
                  src={
                    "https://firebasestorage.googleapis.com/v0/b/nzcountry.appspot.com/o/partners%2FNZAMMO.jpg?alt=media&token=f07f1da0-f039-4a37-8438-fa207d188597"
                  }
                  onClick={() => window.open("https://www.nzammo.co.nz/")}
                  style={{ cursor: "pointer" }}
                />
                <Image
                  boxSize={width < 750 ? 200 : (width * 0.8) / 8}
                  src={
                    "https://firebasestorage.googleapis.com/v0/b/nzcountry.appspot.com/o/partners%2FPROOF.jpg?alt=media&token=11f8058b-d57d-43a6-b4f9-4691897fb3b5"
                  }
                  onClick={() => window.open("https://proofresearch.com/")}
                  style={{ cursor: "pointer" }}
                />
                <Image
                  boxSize={width < 750 ? 200 : (width * 0.8) / 8}
                  src={
                    "https://firebasestorage.googleapis.com/v0/b/nzcountry.appspot.com/o/partners%2FTYRON.jpg?alt=media&token=586f3712-c183-4865-a936-255e8d191d12"
                  }
                  onClick={() =>
                    window.open("https://tyronsouthwardtaxidermy.co.nz/")
                  }
                  style={{ cursor: "pointer" }}
                />
                <Image
                  boxSize={width < 750 ? 200 : (width * 0.8) / 8}
                  src={
                    "https://firebasestorage.googleapis.com/v0/b/nzcountry.appspot.com/o/partners%2FSWS.jpg?alt=media&token=a37da071-5593-4d17-abfe-c88a29b0fcb9"
                  }
                  onClick={() => window.open("https://scryptworxstudios.com/")}
                  style={{ cursor: "pointer" }}
                />
                <Image
                  boxSize={width < 750 ? 200 : (width * 0.8) / 8}
                  src={
                    "https://firebasestorage.googleapis.com/v0/b/nzcountry.appspot.com/o/partners%2FNZPHGA.jpg?alt=media&token=d6f06a42-e5e7-4784-814a-ecde30e5534a"
                  }
                  onClick={() => window.open("https://www.nzphga.com/")}
                  style={{ cursor: "pointer" }}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  paddingTop: "2rem",
                  backgroundImage: `url(${sight})`,
                  backgroundPosition: "center",
                  backgroundSize: "cover",
                  objectFit: "contain",
                  color: "#EA9101",
                  width: "100%",
                  height: "30vh",
                  textAlign: "center",
                }}
              >
                {" "}
                <div>
                  <Image
                    src={logo}
                    //width={width / 6}
                    style={{
                      width: width < 700 ? "250px" : `${width / 7}px`,
                      maxWidth: "150px",
                    }}
                    loading="eager"
                    onClick={() => nav("/inquire")}
                  />
                </div>
                <Button
                  variant="outline"
                  onClick={() => nav("/inquire")}
                  color={"#EA9101"}
                  borderColor={"#EA9101"}
                >
                  Come hunt with us - Inquire
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
