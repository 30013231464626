import { Button, Image } from "@chakra-ui/react";
import { logEvent } from "firebase/analytics";
import { useEffect } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { analytics } from "../firebase";
import { windowSizeState } from "../recoil";
import ram from "./arapawa ram4.png";
import thar from "./Bull thar white.png";
import chamois from "./chamois white transparent.png";
import fallow from "./fallow.png";
import goat from "./Goat Tile.png";
import image from "./nz country red deer.png";
import { Helmet } from "react-helmet";

export const TrophyHunting = () => {
  const { width } = useRecoilValue(windowSizeState);
  const nav = useNavigate();

  useEffect(() => {
    logEvent(analytics, "page_view", { page_title: "Trophy Hunting" });
  }, []);

  const schema = {
    "@context": "https://schema.org",
    "@type": "Product",
    name: "Guided Trophy Hunting in New Zealand",
    image:
      "https://firebasestorage.googleapis.com/v0/b/nzcountry.appspot.com/o/images%2FJim%20and%20Mark_5.10.1.jpg?alt=media&token=f76138c3-e504-45e9-ac93-b39a67ac60a2",
    description:
      "Hunt for trophy deer, fallow deer, goat, tahr, and Arapawa rams in New Zealand.",
    brand: {
      "@type": "Brand",
      name: "NZ COUNTRY",
    },
    offers: {
      "@type": "Offer",
      price: "Starting from $2000",
      priceCurrency: "USD",
      availability: "https://schema.org/InStock",
    },
  };

  return (
    <>
      <Helmet>
        <script type="application/ld+json">{JSON.stringify(schema)}</script>
      </Helmet>
      <div
        style={{
          height: "100vh",
          overflowY: "hidden",
          backgroundColor: "rgba(0,0,0,.4)",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              height: "100vh",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: width < 930 ? "row" : "column",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: width < 930 ? "column" : "row",
                    alignItems: "center",
                  }}
                >
                  <Image
                    src={image}
                    width={width < 930 ? 100 : 200}
                    style={{ cursor: "pointer", opacity: ".8" }}
                    onClick={() => nav("/red-deer")}
                  />
                  <Image
                    src={thar}
                    width={width < 930 ? 100 : 200}
                    style={{ cursor: "pointer", opacity: ".8" }}
                    onClick={() => nav("/tahr")}
                  />
                  <Image
                    src={chamois}
                    width={width < 930 ? 100 : 200}
                    style={{ cursor: "pointer", opacity: ".8" }}
                    onClick={() => nav("/chamois")}
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: width < 930 ? "column" : "row",
                    alignItems: "center",
                  }}
                >
                  <Image
                    src={fallow}
                    width={width < 930 ? 100 : 200}
                    style={{ cursor: "pointer", opacity: ".8" }}
                    onClick={() => nav("/fallow")}
                  />
                  <Image
                    src={ram}
                    width={width < 930 ? 100 : 200}
                    style={{ cursor: "pointer", opacity: ".8" }}
                    onClick={() => nav("/rams")}
                  />
                  <Image
                    src={goat}
                    width={width < 930 ? 100 : 200}
                    style={{ cursor: "pointer", opacity: ".8" }}
                    onClick={() => nav("/goats")}
                  />
                </div>
              </div>
              <Button
                size="lg"
                variant={"link"}
                onClick={() => nav("/")}
                color={"#EA9101"}
                fontFamily={"anton"}
                letterSpacing={1.5}
              >
                HOME
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
