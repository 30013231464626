import { Button, Image } from "@chakra-ui/react";
import { logEvent } from "firebase/analytics";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { analytics, firestore } from "../firebase";
import { windowSizeState } from "../recoil";
import { TeamMember } from "./TeamMember";
import { collection, onSnapshot } from "firebase/firestore";
import { MemberInfo } from "../types/typeDefs";
import logo from "./../Home/nz country.png";
import { Helmet } from "react-helmet";

export const Team = () => {
  const nav = useNavigate();

  const [teamMembers, setTeamMembers] = useState<Array<MemberInfo>>([]);

  useEffect(() => {
    const unsub = onSnapshot(collection(firestore, `team`), (snapshot) => {
      setTeamMembers([]);
      snapshot.forEach((snapDoc) => {
        setTeamMembers((prev) => {
          return [...prev, { ...snapDoc.data(), id: snapDoc.id } as MemberInfo];
        });
      });
    });

    return unsub;
  }, []);
  useEffect(() => {
    logEvent(analytics, "page_view", { page_title: "Team" });
  }, []);
  const { height, width } = useRecoilValue(windowSizeState);

  const schema = {
    "@context": "https://schema.org",
    "@graph": [
      {
        "@type": "Person",
        name: "Mark Lupi",
        jobTitle:
          "Business Owner, Professional Hunting Guide and Firearms Expert",
        worksFor: {
          "@type": "Organization",
          name: "NZ COUNTRY",
        },
        url: "https://www.nzcountry.guide/team",
        image:
          "https://firebasestorage.googleapis.com/v0/b/nzcountry.appspot.com/o/teamImages%2FMark%20Sq.jpg?alt=media&token=c27442ed-8c6d-4839-bffb-70cf543b82f9",
        email: "mailto:mark@nzcountry.guide",
      },
      {
        "@type": "Person",
        name: "James Ready",
        jobTitle: "Senior Professional Hunting Guide",
        worksFor: {
          "@type": "Organization",
          name: "NZ COUNTRY",
        },
        url: "https://www.nzcountry.guide/team",
        image:
          "https://firebasestorage.googleapis.com/v0/b/nzcountry.appspot.com/o/teamImages%2FJames%20Sq.jpg?alt=media&token=465eda00-cae0-47a7-b230-9a22151dccc6",
      },
      {
        "@type": "Person",
        name: "Aaron Shields",
        jobTitle: "Professional Hunting Guide",
        worksFor: {
          "@type": "Organization",
          name: "NZ COUNTRY",
        },
        url: "https://www.nzcountry.guide/team",
        image:
          "https://firebasestorage.googleapis.com/v0/b/nzcountry.appspot.com/o/teamImages%2FAaron%20Sq.jpg?alt=media&token=a4059b58-478c-4759-980b-98bb64cce6a6",
      },
      {
        "@type": "Person",
        name: "Matthew Hodgson",
        jobTitle: "Professional Hunting Guide",
        worksFor: {
          "@type": "Organization",
          name: "NZ COUNTRY",
        },
        url: "https://www.nzcountry.guide/team",
        image:
          "https://firebasestorage.googleapis.com/v0/b/nzcountry.appspot.com/o/teamImages%2FMatt%20Sq.jpg?alt=media&token=468bf35a-a274-4b86-901b-7cc77bc70e19",
      },
      {
        "@type": "Person",
        name: "Ethan Stevenson",
        jobTitle: "Professional Hunting Guide",
        worksFor: {
          "@type": "Organization",
          name: "NZ COUNTRY",
        },
        url: "https://www.nzcountry.guide/team",
        image:
          "https://firebasestorage.googleapis.com/v0/b/nzcountry.appspot.com/o/teamImages%2Fethan_2.73.1.jpg?alt=media&token=e8078d74-7fbe-4446-8dbc-965d18f0e326",
      },
    ],
  };

  return (
    <>
      <Helmet>
        <script type="application/ld+json">{JSON.stringify(schema)}</script>
      </Helmet>
      <div
        style={{
          height: "100vh",
          overflowY: "hidden",
          backgroundColor: "rgba(0,0,0,.95)",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            paddingTop: `${height / 20}px`,
            //paddingBottom: `${height / 20}px`,
            //overflowY: "scroll",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              height: `${(height / 28) * 3}px`,
              width: "95%",
              maxWidth: "1200px",

              color: "#EA9101",
              fontFamily: "anton",
              fontSize: width < 550 ? "30px" : `${width / 30}px`,
              borderRadius: "3px",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                height: "100%",
              }}
            >
              THE HUNT TEAM
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              width: "90%",
              maxWidth: "200px",
              justifyContent: "space-between",
            }}
          >
            <Button
              variant="link"
              size="lg"
              color={"#B0B0A0"}
              onClick={() => nav("/")}
              fontFamily={"anton"}
              letterSpacing={1.5}
            >
              Home
            </Button>
            <Button
              variant="link"
              size="lg"
              color={"#B0B0A0"}
              onClick={() => nav("/trophy-Hunting")}
              fontFamily={"anton"}
              letterSpacing={1.5}
            >
              Trophy Hunts
            </Button>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              height: `${(height / 20) * 15}px`,
              width: "95%",
              maxWidth: "1200px",
              overflowY: "scroll",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
                //backgroundColor: "#70706055",

                borderRadius: "1rem",
              }}
            >
              {teamMembers.length > 0 &&
                teamMembers
                  .sort((a, b) => a.position - b.position)
                  .map((member, idx) => {
                    return <TeamMember teamMember={member} key={idx} />;
                  })}
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                height: "100vh",
                backgroundImage: `url(https://firebasestorage.googleapis.com/v0/b/nzcountry.appspot.com/o/images%2FAndy%20Shooting%20Thar_1.26.1.jpg?alt=media&token=41fdc01b-d4e8-4fc5-b5c5-3d95ad9a2bf6)`,
                backgroundPosition: "center",
                backgroundSize: "cover",
                objectFit: "contain",
                width: "100%",
                maxWidth: "1200px",
                maxHeight: "400px",
                paddingTop: "2rem",
                paddingBottom: "3rem",
              }}
            >
              <div>
                <Image
                  src={logo}
                  width={width / 6}
                  style={{ width: width < 700 ? "250px" : `${width / 7}px` }}
                  loading="eager"
                  onClick={() => nav("/inquire")}
                />
              </div>
              <Button
                variant="outline"
                onClick={() => nav("/inquire")}
                color={"#EA9101"}
                borderColor={"#EA9101"}
              >
                Come hunt with us - Inquire
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
